"use client";

import { useSearchParams } from "next/navigation";
import { sendGTMEvent } from "@next/third-parties/google";
import { Suspense, useEffect } from "react";
import { GoogleTagManager } from "@next/third-parties/google";
import { updateContactCookie } from "@/app/lib/storage";

const TagManagerData = () => {
  const searchParams = useSearchParams();

  const utm_campaign = searchParams.get("utm_campaign");
  const utm_source = searchParams.get("utm_source");
  const utm_medium = searchParams.get("utm_medium");
  const utm_content = searchParams.get("utm_content");
  const utm_term = searchParams.get("utm_term");

  if (utm_campaign || utm_source) {
    fetch("/api/attribution", {
      method: "POST",
      body: JSON.stringify({
        utm_campaign,
        utm_source,
        utm_medium,
        utm_content,
        utm_term,
      }),
    });
  }

  useEffect(() => {
    if (utm_campaign) {
      sendGTMEvent({
        event: "campaign_data",
        value: {
          campaignName: utm_campaign,
          campaignSource: utm_source,
          campaignMedium: utm_medium,
          campaignContent: utm_content,
          campaignTerm: utm_term,
        },
      });
    }
  }, [utm_campaign, utm_source, utm_medium, utm_content, utm_term]);

  return (
    <>
      <GoogleTagManager gtmId="GTM-W9NNBBFJ" />
    </>
  );
};

export const TagManager = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <TagManagerData />
    </Suspense>
  );
};
